import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {numberWithPoints} from "../../../utils/numberWithPoints";
import React from "react";


export default function ParticipantSummaryData({data, key}) {

    const sortedData = data.sort((a, b) => b[key] - a[key])

    const impares = sortedData.filter((item, index) => index % 2 === 0)
    const pares = sortedData.filter((item, index) => index % 2 !== 0)


    return <>
        <Grid container spacing={2} >
            <Grid item xs={6}>
                <Table size="small" aria-label="table with impares background">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>Valor Total em Aberto</TableCell>
                            <TableCell>Quantidade de lotes em Aberto</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {impares?.map((item, idx) => (
                            <TableRow key={idx} className={idx % 2 === 0 ? 'odd-row' : 'even-row'}>
                                <TableCell>{item.nome}</TableCell>
                                <TableCell>R$ {numberWithPoints(item.valor)}</TableCell>
                                <TableCell>{item.quantidade}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>

            <Grid item xs={6}>
                <Table size="small" aria-label="table with pares background">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>Valor Total em Aberto</TableCell>
                            <TableCell>Quantidade de lotes em Aberto</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pares?.map((item, idx) => (
                            <TableRow key={idx} className={idx % 2 === 0 ? 'odd-row' : 'even-row'}>
                                <TableCell>{item.nome}</TableCell>
                                <TableCell>R$ {numberWithPoints(item.valor)}</TableCell>
                                <TableCell>{item.quantidade}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    </>
}