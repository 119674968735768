import PopupAdd from "../../../../component/PopupAdd/PopupAdd";
import React, {useState} from "react";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import InputsAddPayment from "./InputsAddPayment";

export default function PopupAddPayment({baseUrl, setLoadData}) {

    const [data, setData] = useState({})
    const [files, setFiles] = useState([])

    const [error, setError] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")
    const [blockSubmit, setBlockSubmit] = useState("")
    const [clear, setClear] = useState(true)

    const headers = {
        'Content-Type': 'multipart/form-data'
    }

    const inputs = <InputsAddPayment
        setData={setData} files={files} setFiles={setFiles}
        error={error} setBlockSubmit={setBlockSubmit}
        clear={clear} setClear={setClear}
    />

    const icon = <LocalAtmIcon fontSize="large" id="icon-svg"/>

    const clearForm = () => {
        setError('')
        setErrorMessage(false)
        setErrorColumn(false)

        setClear(true)
    }

    const returnData = () => {
        const formData = new FormData();
        formData.append('data', JSON.stringify(data));
        for (let file of files) {
            formData.append('files', file);
        }

        return formData
    }

    const validateData = () => {
        if (data.valor > 0)
            return true
        else {
            setError("O valor deve ser maior que 0!")
            return false
        }
    }

    return <PopupAdd
        baseUrl={baseUrl + '/register'} setLoadData={setLoadData}
        validateData={validateData}
        inputs={inputs} title={'Adicionar Pagamento'} icon={icon}
        clearForm={clearForm} returnData={returnData}
        setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
        blockSubmit={blockSubmit} headers={headers}
        disableButton={files.length < 1}
    />
}