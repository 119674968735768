import {Dropdown} from "react-bootstrap";
import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";
import PopupEditUsers from "./PopupEditUsers";
import UserDetails from "./UserDetails";
import PasswordReset from "./passwordReset";
import Impersonate from "./Impersonate";
import EmailAdicional from "./EmailAdicional";
import TelefoneAdicional from "./TelefoneAdicional";


export default function UserActions({item, setLoadData, baseUrl}) {

    return <Dropdown drop='down-centered'>
        <Dropdown.Toggle variant="secondary">
            Ações &nbsp;
            <SettingsIcon fontSize="medium"/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <UserDetails item={item}/>
            <Impersonate item={item}/>
            <PopupEditUsers item={item} setLoadData={setLoadData}/>
            <EmailAdicional item={item} setLoadData={setLoadData} baseUrl={baseUrl + '/emails'}/>
            <TelefoneAdicional item={item} setLoadData={setLoadData} baseUrl={baseUrl + '/telefones'}/>
            <PasswordReset item={item} setLoadData={setLoadData} baseUrl={baseUrl + '/user/redefinir-senha/'}/>
        </Dropdown.Menu>

    </Dropdown>
}