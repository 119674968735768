import axios from "axios";
import {useUser} from "./AuthProvider";


export const ApiUrl = `${process.env.REACT_APP_BACKEND_LINK}/api`
const isAdmin = (userData) => {
    return userData?.roles.map((item) => item.name).includes('ROLE_ADMIN') ||
        userData?.roles.includes('ROLE_ADMIN')
}

const isGroupLeader = (userData) => {
    console.log("Validando..." + userData?.roles.map((item) => item.name).includes('ROLE_GROUP_LEADER') ||
        userData?.roles.includes('ROLE_GROUP_LEADER'))
    return userData?.roles.map((item) => item.name).includes('ROLE_GROUP_LEADER') ||
        userData?.roles.includes('ROLE_GROUP_LEADER')
}

export default function useAuthMethods() {

    const {user, setUser, removeUser} = useUser();

    const baseUrl = `${ApiUrl}/auth`

    // this method can return 5 things:
    // 0 - Unauthorized 1 - two-step verification
    // 2 - login successfully
    // 3 - first login 4 - password expired
    // 5 - error 6 - user blocked due to inactivity
    // 7 - user blocked by admin
    const login = async (login, password) => {
        const data = {login, password};
        removeUser()
        try {
            const response =
                await axios.post(baseUrl + '/login', data,
                    {
                        headers: {
                            'Authorization': null
                        }
                    }
                );

            if (response.data.message === 'Primeiro acesso, redirecionando para a alteração de senha')
                return 3;
            if (response.data.message) return 1;

            if (response.data.token) {
                let userData = response.data
                userData.isAdmin = isAdmin(userData)
                userData.isGroupLeader = isGroupLeader(userData)
                setUser(userData)
                axios.defaults.headers.common['Authorization'] = `Bearer ${userData?.token}`;
                return 2;
            }

            return 0;
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    // return 400 if the user not exists
                    return 0
                } else if (error.response.status === 500 && error.response.data.message === 'Login falhou!') {
                    // have user but not passed the correct password
                    return 0
                } else if (error.response.status === 500 && error.response.data.message === 'Senha do usuário está expirada!') {
                    // have user but not passed the correct password
                    return 4
                } else if (error.response.status === 500 && error.response.data.message === 'Usuário está bloqueado!') {
                    // user blocked by admin
                    return 7
                } else if (
                    error.response.status === 401 ||
                    (error.response.status === 500 && error.response.data.message === 'Usuário está inativo!')
                ) {
                    // to disabled user
                    return 6
                }
            } else {
                return 5
            }
        }
    };

    // this method can return 3 things:
    // 0 - Unauthorized 1 - login successfully 2 - error
    const twoStepVerification = async (code, login) => {

        const data = {code, login}

        try {
            const response =
                await axios.post(baseUrl + '/verify-2fa', data);

            if (response.data.token) {
                let userData = response.data
                userData.isAdmin = isAdmin(userData)
                userData.isGroupLeader = isGroupLeader(userData)
                setUser(userData)
                axios.defaults.headers.common['Authorization'] = `Bearer ${userData?.token}`;
                return 1
            }

            return 0
        } catch (error) {
            if (error.response.status === 400) {
                // return 400 if the user not exists
                return 0
            } else return 2
        }
    }

    const validateToken = async () => {
        try {
            const response = await axios.get(ApiUrl + '/validate', {
                headers: {
                    'Authorization': `Bearer ${user?.token}`
                },
            })
            let userData = response.data
            userData.isAdmin = isAdmin(userData)
            userData.isGroupLeader = isGroupLeader(userData)
            userData.token = user?.token
            setUser(userData)
            return true
        } catch (_) {
            return false
        }
    }

    return {login, twoStepVerification, validateToken}
}
