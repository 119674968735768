import * as React from "react";
import {useEffect, useState} from "react";
import {PieChart} from "@mui/x-charts";
import axios from "axios";
import BasicLoader from "../../../component/BasicLoader/BasicLoader";
import {ApiUrl} from "../../../auth/authMethods";
import {useUser} from "../../../auth/AuthProvider";

export default function PieGraph({item, onClick, setStatus}) {
    const [percentageData, setPercentageData] = useState("");
    const [responseData, setResponseData] = useState("");

    const {user} = useUser()

    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${user?.token}`;
        axios
            .get(`${ApiUrl}/dashboard/percentual`)
            .then(function (response) {
                setResponseData(response.data);
                processData(response.data)
            });
    }, []);

    const processData = (data) => {
        const total = data.totalBoletos

        const percentages = {}
        percentages.totalCriado = ((data.totalCriado * 100) / total).toFixed(2)
        percentages.totalVencido = ((data.totalVencido * 100) / total).toFixed(2)
        percentages.totalPagoEmDia = ((data.totalPagoEmDia * 100) / total).toFixed(2)
        percentages.totalPagoAtrasado = ((data.totalPagoAtrasado * 100) / total).toFixed(2)
        percentages.totalCancelado = ((data.totalCancelado * 100) / total).toFixed(2)

        setPercentageData(percentages)
    }

    return percentageData ? (<div
        sx={{mt: 1.6, height: "80%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "24px"}}
        content={false}>
        <PieChart
            colors={["#77dd77", "#eb9536", "#ff6961", "#00a6ff", "#a4a4a4"]}
            series={[
                {
                    arcLabel: (item) => `${item.value}%`,
                    data: [
                        {
                            id: 0,
                            value: Number(percentageData.totalPagoEmDia),
                            label: `Boletos Pagos: ${responseData.totalPagoEmDia}`,
                        },
                        {
                            id: 1,
                            value: Number(percentageData.totalPagoAtrasado),
                            label: `Boletos Pagos com atraso: ${responseData.totalPagoAtrasado}`,
                        },
                        {
                            id: 2,
                            value: Number(percentageData.totalVencido),
                            label: `Boletos Vencidos: ${responseData.totalVencido}`,
                        },
                        {
                            id: 3,
                            value: Number(percentageData.totalCriado),
                            label: `Boletos Em Aberto: ${responseData.totalCriado}`,
                        },
                        {
                            id: 4,
                            value: Number(percentageData.totalCancelado),
                            label: `Boletos Cancelados: ${responseData.totalCancelado}`,
                        },
                    ],
                    highlightScope: {faded: "global", highlighted: "item"},
                    faded: {innerRadius: 30, additionalRadius: -30, color: "gray"},
                    cx: 170,
                    cy: 110,
                    arcLabelMinAngle: 25,
                    innerRadius: 40,
                    valueFormatter: (value) => `${value.value} %`,
                },
            ]}
            height={230}
        />
    </div>) : (
        <BasicLoader
            message={"Carregando..."}
            height={10}
            notLeftShow={true}
        />
    );
}
