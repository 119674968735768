import React, {useEffect, useState} from "react";
import TableList from "../../../component/TableList/TableList";
import {ApiUrl} from "../../../auth/authMethods";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import PopupWriteOff from "./PopupWriteOff";
import {numberWithPoints} from "../../../utils/numberWithPoints";
import {useUser} from "../../../auth/AuthProvider";
import axios from "axios";
import DownloadIcon from '@mui/icons-material/Download';
import LoadingButton from "@mui/lab/LoadingButton";
import PopupGenerateDuplicate from "./PopupGenerateDuplicate";
import SaveIcon from "@mui/icons-material/Save";

export default function ParcelList({orderId, setListLoadData}) {

    const {user} = useUser()

    const url = ApiUrl + '/parcelas/list/' + orderId + '?sort=numero,desc'
    const [loadData, setLoadData] = useState(true)

    const [data, setData] = useState([])
    const [error, setError] = useState(false)

    const [downloadingList, setDownloadingList] = useState([])

    const [downloading, setDownloading] = useState(false)
    const [downloadingAll, setDownloadingAll] = useState(false)

    const titles = user.isAdmin ? [
        'Número',
        'Vencimento',
        'Valor',
        'Status',
        'Baixa Manual',
    ] : [
        'Número',
        'Vencimento',
        'Valor',
        'Status',
    ]

    const readData = (res) => {
        return res.data
    }

    const handleDownload = async (id, filename) => {

        if (!id)
            return
        const url = `${ApiUrl}/boletos/generate/${id}`

        setDownloadingList([...downloadingList, id])
        axios.post(url, {}, {responseType: 'arraybuffer'})
            .then(response => {
                // Cria um objeto Blob a partir do array de bytes recebido na resposta
                const blob = new Blob([response.data], {type: 'application/octet-stream'});

                // Cria um link temporário para o Blob e o adiciona ao documento
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename ? filename : 'boleto.pdf'; // Define o nome do arquivo a ser baixado
                document.body.appendChild(link);

                // Simula um clique no link para iniciar o download
                link.click();

                // Remove o link temporário do documento
                document.body.removeChild(link);
            })
            .catch(error => console.error('Erro ao gerar boleto:', error))
            .finally(() => {
                setDownloadingList(downloadingList.splice(downloadingList.indexOf(id), 1))
            })

    }

    const handleDownloadAll = async () => {


        const url = `${ApiUrl}/boletos/generate/all/${orderId}`
        setDownloadingAll(true)
        axios.post(url, {}, {responseType: 'arraybuffer'})
            .then(response => {
                // Cria um objeto Blob a partir do array de bytes recebido na resposta
                const blob = new Blob([response.data], {type: 'application/octet-stream'});

                // Cria um link temporário para o Blob e o adiciona ao documento
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'boletos.pdf'; // Define o nome do arquivo a ser baixado
                document.body.appendChild(link);

                // Simula um clique no link para iniciar o download
                link.click();

                // Remove o link temporário do documento
                document.body.removeChild(link);
            })
            .catch(error => console.error('Erro ao gerar boleto:', error))
            .finally(() => {
                setDownloadingAll(false)
            })

    }

    const downloadAll = async () => {
        setDownloading(true);

        try {
            // Cria uma lista de promessas de download
            const downloadPromises = data
                .filter((boleto) => boleto.status === 'CRIADA' || boleto.status === 'VENCIDA')
                .sort((a, b) => a.numero - b.numero).map((item) => {
                    const url = `${ApiUrl}/boletos/generate/${item?.boleto?.id}`;

                    return axios.post(url, {}, {responseType: 'arraybuffer'})
                        .then(response => {
                            // Cria um objeto Blob a partir do array de bytes recebido na resposta
                            const blob = new Blob([response.data], {type: 'application/octet-stream'});

                            // Cria um link temporário para o Blob e o adiciona ao documento
                            const link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = `boleto-parcela-${item.numero}.pdf`; // Define o nome do arquivo a ser baixado
                            document.body.appendChild(link);

                            // Simula um clique no link para iniciar o download
                            link.click();

                            // Remove o link temporário do documento
                            document.body.removeChild(link);
                        })
                        .catch(error => {
                            console.error('Erro ao gerar boleto:', error);
                            // Lança o erro para ser tratado pelo Promise.all
                            throw error;
                        });
                });

            // Aguarda a conclusão de todas as promessas de download
            await Promise.all(downloadPromises);
        } catch (error) {
            console.error('Erro ao baixar todos os boletos:', error);
        } finally {
            setDownloading(false);
        }
    }


    return (
        <>
            <div className='d-flex justify-content-between align-items-center'>

                <h4>Parcelas</h4>

                <div className='d-flex'>
                    <p className='mt-1'>Baixar todos:</p>
                    &nbsp;&nbsp;
                    <LoadingButton
                        onClick={downloadAll}
                        loading={downloading}
                        loadingPosition="start"
                        startIcon={<SaveIcon/>}
                        variant="contained"
                    >
                    <span>
                        &nbsp;Arquivos separados
                    </span>
                    </LoadingButton>
                    &nbsp;&nbsp;
                    <LoadingButton
                        onClick={handleDownloadAll}
                        loading={downloadingAll}
                        loadingPosition="start"
                        startIcon={<SaveIcon/>}
                        variant="contained"
                    >
                    <span>
                        &nbsp;Arquivo único
                    </span>
                    </LoadingButton>
                </div>

            </div>

            <TableList
                titles={titles} baseUrl={url}
                page={0} size={2000} sort={'vencimento'}
                loadData={loadData}
                setTotalPages={() => {
                }}
                setTotalElements={() => {
                }}
                setData={setData} setLoadData={setLoadData}
                error={error} setError={setError}
                readData={readData}
            >
                {data.sort((a, b) => a.numero - b.numero).map((item) =>
                    <tr key={item.id}>
                        <td>{item.numero}</td>
                        <td>{normalizeTimestemp(item.vencimento, true)}</td>
                        <td>R$ {numberWithPoints(item.valor)}</td>
                        <td>{item.status}</td>
                        {user.isAdmin && <>
                            <td>{item.baixaManual ?
                                'Efetuada' : 'Não Efetuada'
                            }</td>
                        </>
                        }

                        <td>
                            {item.status === 'VENCIDO' &&
                                <PopupGenerateDuplicate item={item} setLoadData={setListLoadData}/>
                            }

                            {item.status !== 'VENCIDO' && item.status !== 'PAGA' && item.status !== 'CANCELADA' && item.boleto &&
                                <>
                                    <LoadingButton
                                        title='Fazer download do boleto'
                                        loading={downloadingList.find(id => id === item?.boleto?.id)}
                                        onClick={() => handleDownload(item?.boleto?.id, item?.boleto?.filename)}
                                        variant="text">
                                        <DownloadIcon/>
                                    </LoadingButton>

                                    {user?.isAdmin &&
                                        <>
                                            <PopupWriteOff item={item} setLoadData={setListLoadData}/>
                                            &nbsp;
                                        </>
                                    }
                                </>
                            }
                        </td>

                    </tr>
                )}
            </TableList>
        </>
    )
}