import React, { useState, useEffect } from "react";
import { Button, Form, Table } from "react-bootstrap";
import LargeModal from "../../../component/LargeModel";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from "@mui/icons-material/Email";
import {useUser} from "../../../auth/AuthProvider";
import axios from "axios";

const EmailAdicional = ({ item, baseUrl, setLoadData, children }) => {
    const [emails, setEmails] = useState([]);
    const [newEmail, setNewEmail] = useState("");
    const [newTipoEmail, setNewTipoEmail] = useState("PRINCIPAL");
    const [lgShow, setLgShow] = useState(false);
    const [emailError, setEmailError] = useState("");

    const {user} = useUser();

    useEffect(() => {
        if (lgShow) {
            const fetchEmails = async () => {
                const userId = item.id;

                const headers = {
                    Authorization: `Bearer ${user?.token}`,
                    "Content-Type": "application/json",
                };

                try {
                    const response = await axios.get(`${baseUrl}/list?userId=${userId}`, { headers });
                    if (response.status === 200) {

                        const transformedEmails = response.data.map(email => ({
                            ...email,
                            tipoEmail: email.tipoEmail === "EMAIL_PRINCIPAL" ? "PRINCIPAL" : "ALERTA"
                        }));

                        setEmails(transformedEmails);
                    } else {
                        console.log("Erro ao buscar emails.");
                    }
                } catch (error) {
                    console.error("Erro ao buscar os dados:", error);
                    alert("Erro ao carregar emails.");
                }
            };

            fetchEmails();
        }
    }, [lgShow, baseUrl, item.id, user?.token]);

    const handleAddEmail = () => {

        const isValidEmail = (email) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        };

        if (!isValidEmail(newEmail)) {
            setEmailError("Por favor, insira um email válido.");
            return;
        }

        setEmailError("");

        if (newEmail.trim()) {
            setEmails([...emails, { email: newEmail.trim(), tipoEmail: newTipoEmail }]);
            setNewEmail("");
            setNewTipoEmail("PRINCIPAL");
        } else {
            alert("Digite um email válido antes de adicionar.");
        }
    };

    const handleRemoveEmail = (index) => {
        setEmails(emails.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const userId = item.id;

        const transformedEmails = emails.map(email => ({
            ...email,
            tipoEmail: email.tipoEmail === "PRINCIPAL" ? "EMAIL_PRINCIPAL" : "EMAIL_ALERTA"
        }));

        const payload = { emails: transformedEmails };

        const headers = {
            Authorization: `Bearer ${user?.token}`,
            "Content-Type": "application/json",
        };

        try {
            const response = await axios.post(
                `${baseUrl}/register?userId=${userId}`,
                payload,
                { headers }
            );

            if (response.status === 200) {
                setLoadData(true);
                setLgShow(false);
            } else {
                console.log("Erro ao cadastrar emails.");
            }
        } catch (error) {
            console.error("Erro ao enviar os dados:", error);
            alert("Erro de conexão.");
        }
    };

    const buttonText = <>
        <EmailIcon fontSize="medium" />
        &nbsp; Emails Adicionais
    </>;

    const iconSpan = (
        <span style={{ fontSize: 100 }}>📧</span>
    );

    return (
        <>
            <LargeModal
                buttonClass="dropdown-item"
                buttonText={buttonText}
                title="Gerenciar Emails Adicionais"
                iconSpan={iconSpan}
                lgShow={lgShow}
                setLgShow={setLgShow}
                buttonId={"email-action"}
            >
                <div className="message-container">
                    <span><b>Adicionar e Gerenciar Emails</b></span>
                    <hr />
                    <div className="message-content">
                        <p>
                            Insira os emails adicionais e defina seus tipos como **PRINCIPAL** ou **ALERTA**.
                            Se escolher o tipo **PRINCIPAL**, todos os emails relacionados a boletos enviados ao email do participante também serão enviados para esse email.
                            Já o tipo **ALERTA** será utilizado exclusivamente para o envio de notificações de expiração de boleto.
                        </p>
                    </div>
                    <hr />
                </div>
                <div>
                    <Form onSubmit={handleSubmit} className="form-inputs" style={{
                        width: "100%",
                        maxWidth: "1200px",
                        padding: "20px",
                        margin: "0 auto",
                    }}>
                        {children}
                        {emailError && (
                            <span style={{ color: "red", fontSize: "0.9rem", textAlign: "left", display: "flex", marginBottom: 20 }}>
                                    {emailError}
                                </span>
                        )}
                        <div className="d-flex mb-3 align-items-center">
                            <Form.Control
                                type="email"
                                placeholder="Digite o email"
                                value={newEmail}
                                onChange={(e) => setNewEmail(e.target.value)}
                                className="me-2"
                            />
                            <Form.Select
                                value={newTipoEmail}
                                onChange={(e) => setNewTipoEmail(e.target.value)}
                                className="me-2"
                            >
                                <option value="PRINCIPAL">PRINCIPAL</option>
                                <option value="ALERTA">ALERTA</option>
                            </Form.Select>
                            <Button variant="success" onClick={handleAddEmail}>
                                <AddCircleIcon />
                            </Button>
                        </div>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>Email</th>
                                <th>Tipo</th>
                                <th>Ações</th>
                            </tr>
                            </thead>
                            <tbody>
                            {emails.map((email, index) => (
                                <tr key={index}>
                                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                        {email.email}
                                    </td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                        {email.tipoEmail}
                                    </td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                        <Button
                                            variant="danger"
                                            onClick={() => handleRemoveEmail(index)}
                                        >
                                            <DeleteIcon />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                            {emails.length === 0 && (
                                <tr>
                                    <td
                                        colSpan="3"
                                        className="text-center"
                                        style={{ textAlign: "center", verticalAlign: "middle" }}
                                    >
                                        Nenhum email adicionado.
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                        <div className="mt-3">
                            <Button type="submit" className="btn-primary">
                                Salvar
                            </Button>
                        </div>
                    </Form>
                </div>
            </LargeModal>
        </>
    );
};

export default EmailAdicional;
