import {useUser} from "../../auth/AuthProvider";
import InstitutionalHomePage from "./InstitutionalHomePage";
import { Navigate } from "react-router-dom";
import AdminHomePage from "./AdminHomePage/AdminHomePage";

export default function HomePage() {

    const {user} = useUser()

    return user?.token ?
        user?.isAdmin ?
            <AdminHomePage/> :
            <Navigate to="/orders/bought"/> :
        <InstitutionalHomePage/>
}