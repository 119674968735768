import {ApiUrl} from "../../../auth/authMethods";
import {useState} from "react";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

export default function DownloadFilesFromPayment({files}) {

    const [loading, setLoading] = useState(false)

    const downloadFiles = async () => {

        setLoading(true)

        for (const file of files) {
            await downloadFile(file)
        }

        setLoading(false)
    }


    const downloadFile = async ({id, fileName}) => {

        const axiosConfig = {
            method: 'get',
            url: ApiUrl + '/pagamentos/download-file/' + id,
            responseType: 'blob'
        };

        // Realiza a requisição Axios
        await axios(axiosConfig)
            .then(response => {
                // Cria um objeto URL para o blob
                const blobUrl = URL.createObjectURL(response.data);

                // Cria um link para download
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = fileName;

                // Adiciona o link ao corpo do documento
                document.body.appendChild(link);

                // Simula um clique no link para iniciar o download
                link.click();

                // Remove o link do corpo do documento após o download
                document.body.removeChild(link);
            })
            .catch(error => console.error('Erro baixar evidencia de id ', id, ': ', error))

    }


    return <span className="dropdown-item" style={{cursor: "pointer"}}>
        <LoadingButton
            onClick={downloadFiles}
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon/>}
            variant="text"
        >
            <span>
                &nbsp;Baixar Arquivos
            </span>
        </LoadingButton>
    </span>
}

