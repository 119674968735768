import {useUser} from "../../../auth/AuthProvider";
import {Dropdown} from "react-bootstrap";
import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";
import PopupCancelPayment from "./PopupCancelPayment";
import PopupPaymentDetails from "./PopupPaymentDetails";
import DownloadPaymentReport from "./DownloadPaymentReport";
import DownloadPaymentReceipt from "./DownloadPaymentReceipt";
import PopupDeletePayment from "./PopupDeletePayment";
import ResendPayment from "./ResendPayment";
import DownloadFilesFromPayment from "./DownloadFilesFromPayment";


export default function PaymentActions({item, setLoadData}) {

    const {user} = useUser()

    return <Dropdown drop='down-centered'>
        <Dropdown.Toggle variant="secondary">
            Ações &nbsp;
            <SettingsIcon fontSize="medium"/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <PopupPaymentDetails item={item}/>
            {item.tipoPagamento !== 'ANTECIPACAO' && <>
                <DownloadPaymentReport id={item.id}/>
            </>}
            {item.tipoPagamento === 'ANTECIPACAO' && item.arquivosPagamento?.length > 0 && <>
                <DownloadFilesFromPayment files={item.arquivosPagamento}/>
            </>}
            {item.status === 'PAGO' && <>
                <DownloadPaymentReceipt id={item.id}/>
            </>}
            {(item.status === 'CRIADO' || item.status === 'INATIVO') && user.isAdmin && <>
                <ResendPayment setLoadData={setLoadData} pagamento={item}/>
            </>}
            {(item.status === 'CRIADO' || item.status === 'ENVIADO') && user.isAdmin && <>
                <PopupCancelPayment setLoadData={setLoadData} item={item}/>
            </>}
            {(
                user.isAdmin &&
                item.status === 'INATIVO' &&
                (
                    item.statusBtg === 'FAILED' ||
                    item.statusBtg === 'CANCELED' ||
                    (item.statusBtg === null && item.paymentId === null)
                )
            ) && <>
                <PopupDeletePayment setLoadData={setLoadData} item={item}/>
            </>}
        </Dropdown.Menu>
    </Dropdown>
}