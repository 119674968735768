import {useEffect, useState} from "react";
import {useUser} from "../../../auth/AuthProvider";
import axios from "axios";
import {ApiUrl} from "../../../auth/authMethods";
import {LineChart} from "@mui/x-charts";
import BasicLoader from "../../../component/BasicLoader/BasicLoader";
import {numberWithPoints} from "../../../utils/numberWithPoints";


export default function ProjectionGraph() {
    const [isLoading, setIsLoading] = useState(true);
    const [responseData, setResponseData] = useState([]);

    const {user} = useUser()

    useEffect(() => {
        setIsLoading(true)
        axios.defaults.headers.common['Authorization'] = `Bearer ${user?.token}`;
        axios
            .get(`${ApiUrl}/dashboard/projections`)
            .then(function (response) {
                setResponseData(response.data);
            }).finally(() => setIsLoading(false))
    }, []);


    // Dados separados por linhas
    const labels = responseData.map((item) => item.month);
    const valuesToReceive = responseData.map((item) => Number(item.valueToReceive));
    const valuesToRepass = responseData.map((item) => Number(item.valueToRepass));
    const lucros = responseData.map((item) => Number(item.lucro));


    return isLoading ? <BasicLoader/> : <>
        <LineChart
            title={'Projeção'}
            series={[
                {
                    color: "#00a6ff",
                    data: valuesToReceive,
                    label: 'Valor à Receber',
                    valueFormatter: (value) => `R$ ${numberWithPoints(value.toFixed(2))}`, // Formata valores com "R$"
                },
                {
                    color: "#ff6961",
                    data: valuesToRepass,
                    label: 'Valor à Repasaar',
                    valueFormatter: (value) => `R$ ${numberWithPoints(value.toFixed(2))}`, // Formata valores com "R$"
                },
            ]}
            xAxis={[{scaleType: 'point', data: labels}]}
            height={400}
            width={600}
        />
        <LineChart
            series={[
                {
                    color: "#77dd77",
                    data: lucros, label: 'Lucro',
                    valueFormatter: (value) => `R$ ${numberWithPoints(value.toFixed(2))}`, // Formata valores com "R$"
                },
            ]}
            xAxis={[{scaleType: 'point', data: labels}]}
            height={400}
            width={600}
        />
    </>
}