import React, { useState, useEffect } from "react";
import { Button, Form, Table } from "react-bootstrap";
import LargeModal from "../../../component/LargeModel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import PhoneIcon from "@mui/icons-material/Phone";
import { useUser } from "../../../auth/AuthProvider";
import axios from "axios";

const TelefoneAdicional = ({ item, baseUrl, setLoadData, children }) => {
    const [telefones, setTelefones] = useState([]);
    const [newTelefone, setNewTelefone] = useState("");
    const [newTipoTelefone, setNewTipoTelefone] = useState("PRINCIPAL");
    const [lgShow, setLgShow] = useState(false);

    const { user } = useUser();

    // Função para aplicar máscara
    const aplicarMascaraTelefone = (telefone) => {
        const somenteNumeros = telefone.replace(/\D/g, "");
        if (somenteNumeros.length <= 10) {
            return somenteNumeros.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
        }
        return somenteNumeros.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    };

    // Função para remover a máscara
    const removerMascaraTelefone = (telefone) => telefone.replace(/\D/g, "");

    // Atualizar telefone com a máscara ao digitar
    const handleTelefoneChange = (e) => {
        setNewTelefone(aplicarMascaraTelefone(e.target.value));
    };

    useEffect(() => {
        if (lgShow) {
            const fetchTelefones = async () => {
                const userId = item.id;

                const headers = {
                    Authorization: `Bearer ${user?.token}`,
                    "Content-Type": "application/json",
                };

                try {
                    const response = await axios.get(`${baseUrl}/list?userId=${userId}`, { headers });
                    if (response.status === 200) {

                        const transformedTelefones = response.data.map(email => ({
                            ...email,
                            tipoTelefone: email.tipoTelefone === "TELEFONE_PRINCIPAL" ? "PRINCIPAL" : "ALERTA"
                        }));

                        setTelefones(transformedTelefones);
                    } else {
                        console.log("Erro ao buscar telefones.");
                    }
                } catch (error) {
                    console.error("Erro ao buscar os dados:", error);
                    alert("Erro ao carregar telefones.");
                }
            };

            fetchTelefones();
        }
    }, [lgShow, baseUrl, item.id, user?.token]);

    const handleAddTelefone = () => {
        if (newTelefone.trim()) {
            const telefoneSemMascara = removerMascaraTelefone(newTelefone.trim());
            setTelefones([...telefones, { telefone: telefoneSemMascara, tipoTelefone: newTipoTelefone }]);
            setNewTelefone("");
            setNewTipoTelefone("PRINCIPAL");
        } else {
            alert("Digite um telefone válido antes de adicionar.");
        }
    };

    const handleRemoveTelefone = (index) => {
        setTelefones(telefones.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const userId = item.id;

        const transformedTelefones = telefones.map(telefone => ({
            ...telefone,
            tipoTelefone: telefone.tipoTelefone === "PRINCIPAL" ? "TELEFONE_PRINCIPAL" : "TELEFONE_ALERTA"
        }));

        const payload = { telefones: transformedTelefones };

        const headers = {
            Authorization: `Bearer ${user?.token}`,
            "Content-Type": "application/json",
        };

        try {
            const response = await axios.post(
                `${baseUrl}/register?userId=${userId}`,
                payload,
                { headers }
            );

            if (response.status === 200) {
                setLoadData(true);
                setLgShow(false);
            } else {
                console.log("Erro ao cadastrar telefones.");
            }
        } catch (error) {
            console.error("Erro ao enviar os dados:", error);
            alert("Erro de conexão.");
        }
    };

    const buttonText = <>
        <PhoneIcon fontSize="medium" />
        &nbsp; Telefones Adicionais
    </>;

    const iconSpan = (
        <span style={{ fontSize: 100 }}>📞</span>
    );

    return (
        <>
            <LargeModal
                buttonClass="dropdown-item"
                buttonText={buttonText}
                title="Gerenciar Telefones Adicionais"
                iconSpan={iconSpan}
                lgShow={lgShow}
                setLgShow={setLgShow}
                buttonId={"telefone-action"}
            >
                <div className="message-container">
                    <span><b>Adicionar e Gerenciar Telefones</b></span>
                    <hr />
                    <div className="message-content">
                        <p>
                            Insira os telefones adicionais e defina seus tipos como **PRINCIPAL** ou **ALERTA**.
                            Telefones do tipo **PRINCIPAL** são utilizados para contato principal.
                            Já os do tipo **ALERTA** serão usados exclusivamente para notificações de emergência.
                        </p>
                    </div>
                    <hr />
                </div>
                <div>
                    <Form onSubmit={handleSubmit} className="form-inputs" style={{
                        width: "100%",
                        maxWidth: "1200px",
                        padding: "20px",
                        margin: "0 auto",
                    }}>
                        {children}
                        <div className="d-flex mb-3 align-items-center">
                            <Form.Control
                                type="text"
                                placeholder="Digite o telefone"
                                value={newTelefone}
                                onChange={handleTelefoneChange}
                                className="me-2"
                                maxLength={11}
                            />
                            <Form.Select
                                value={newTipoTelefone}
                                onChange={(e) => setNewTipoTelefone(e.target.value)}
                                className="me-2"
                            >
                                <option value="PRINCIPAL">PRINCIPAL</option>
                                <option value="ALERTA">ALERTA</option>
                            </Form.Select>
                            <Button variant="success" onClick={handleAddTelefone}>
                                <AddCircleIcon />
                            </Button>
                        </div>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>Telefone</th>
                                <th>Tipo</th>
                                <th>Ações</th>
                            </tr>
                            </thead>
                            <tbody>
                            {telefones.map((telefone, index) => (
                                <tr key={index}>
                                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                        {aplicarMascaraTelefone(telefone.telefone)}
                                    </td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                        {telefone.tipoTelefone}
                                    </td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                        <Button
                                            variant="danger"
                                            onClick={() => handleRemoveTelefone(index)}
                                        >
                                            <DeleteIcon />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                            {telefones.length === 0 && (
                                <tr>
                                    <td
                                        colSpan="3"
                                        className="text-center"
                                        style={{ textAlign: "center", verticalAlign: "middle" }}
                                    >
                                        Nenhum telefone adicionado.
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                        <div className="mt-3">
                            <Button type="submit" className="btn-primary">
                                Salvar
                            </Button>
                        </div>
                    </Form>
                </div>
            </LargeModal>
        </>
    );
};

export default TelefoneAdicional;
