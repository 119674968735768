import ContentContainer from "../../../component/ContentContainer";
import PieGraph from "./PieGraph";
import TotalGrid from "./TotalGrid";
import MainCard from "../../../component/MainCard";
import Grid from "@mui/material/Grid";
import ProjectionGraph from "./ProjectionGraph";
import ParticipantSummary from "./ParticipantSummary";
import * as React from "react";

export default function AdminHomePage() {

    return <ContentContainer width='xl'>
        <h3 className={'text-center'}>Bem vindo(a) ao Rural Crédito!</h3>
        <hr/>

        <Grid container rowSpacing={4.5} columnSpacing={3} display='flex'>
            <Grid item xs={12} sm={6} md={4} lg={6}>
                <MainCard sx={{
                    mt: 4,
                    mb: 2,
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                }} content={false}>
                    <h1>Boletos</h1>
                    <PieGraph/>
                </MainCard>
            </Grid>

            <TotalGrid/>
        </Grid>

        <hr/>
        <h3 className='w-100 ' style={{marginBottom: '-1%', textAlign: 'center'}}>Projeção dos próximos 12 meses</h3>
        <Grid container display='flex'>
            <ProjectionGraph/>
        </Grid>

        <hr/>
        <h3 className='w-100 ' style={{marginBottom: '-1%', textAlign: 'center'}}>Maiores Participantes</h3>
        <ParticipantSummary/>
    </ContentContainer>
}