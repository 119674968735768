import * as React from "react";
import {useEffect, useState} from "react";
import {PieChart} from "@mui/x-charts";
import axios from "axios";
import BasicLoader from "../../../component/BasicLoader/BasicLoader";
import {ApiUrl} from "../../../auth/authMethods";
import {useUser} from "../../../auth/AuthProvider";
import Grid from "@mui/material/Grid";
import MainCard from "../../../component/MainCard";
import {numberWithPoints} from "../../../utils/numberWithPoints";
import Select from "react-select";
import Popup from "../../../component/Popup/Popup";
import ParticipantSummaryData from "./ParticipantSummaryData";

export default function ParticipantSummary() {
    const [buyers, setBuyers] = useState([]);
    const [buyersPopup, setBuyersPopup] = useState(false);


    const [sellers, setSellers] = useState([]);
    const [sellersPopup, setSellersPopup] = useState(false);


    const [responseData, setResponseData] = useState([]);
    const {user} = useUser();

    const [key, setKey] = useState('valor');
    const options = [
        {value: 'valor', label: 'Valor Total de Parcelas Em Aberto'},
        {value: 'quantidade', label: 'Quantidade de Lotes Em Aberto'},
    ]

    const colors = [
        "#ffe231",
        "#5dffd7",
        "#9f55ff",
        "#009600",
        "#c07c2d",
        "#ac1f19",
        "#00a6ff",
    ]

    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${user?.token}`;
        axios
            .get(`${ApiUrl}/dashboard/participant-summary`)
            .then((response) => {
                setResponseData(response.data)
                setBuyers(processData(response.data.compradores))
                setSellers(processData(response.data.vendedores))
            });
    }, []);

    const processData = (data) => {
        const total = data.reduce((sum, item) => sum + item[key], 0);

        const processedData = [];
        let othersValue = 0;
        let othersCount = 0;

        data.sort((a, b) => b[key] - a[key]).forEach((item, idx) => {
            if (idx > 5) {
                othersValue += item[key];
                othersCount++
            } else {
                processedData.push(
                    {
                        valuePercentage: ((item[key] / total) * 100).toFixed(2),
                        label: `${item.nome}`,
                        value: item[key],
                        quantity: item.quantidade,
                    }
                );
            }
        });

        if (othersValue > 0) {
            processedData.push(
                // { label: "Outros", value: othersValue }
                {
                    valuePercentage: ((othersValue / total) * 100).toFixed(2),
                    label: `Outros`,
                    value: othersValue,
                    quantity: othersCount,
                }
            );
        }

        return processedData
    };


    useEffect(() => {
        console.log(key)
        if (responseData?.compradores?.length > 0) {
            setBuyers(processData(responseData.compradores))
            setSellers(processData(responseData.vendedores))
        }
    }, [key])


    useEffect(() => {
        console.log(buyers, sellers)
    }, [buyers, sellers])

    return buyers.length ? (<>
        <Grid container  columnSpacing={3} display='flex'>
        <div className="w-100 mt-4 d-flex justify-content-center mb-4">

            <p className={'mt-2 mb-0'}>Gráficos com base em: </p>
            &nbsp;&nbsp;
            <Select
                options={options}
                defaultValue={options[0]}
                onChange={item => setKey(item.value)}
            />
        </div>

        <Grid item xs={12} sm={6} md={4} lg={6}>
            <MainCard sx={{
                mb: 2,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
            }} content={false}>
                <h1>Compradores</h1>
                <PieChart
                    colors={colors}
                    series={[
                        {
                            arcLabel: (item) => `${item.value}%`,
                            data: buyers.map((item) => ({
                                id: item.label,
                                value: Number(item.valuePercentage),
                                label: key === 'valor' ?
                                    `${item.label} - R$ ${numberWithPoints(item.value)}` :
                                    `${item.label} - ${item.value} lote(s)`,
                            })),
                            highlightScope: {faded: "global", highlighted: "item"},
                            faded: {innerRadius: 30, additionalRadius: -30, color: "gray"},
                            cx: 120,
                            cy: 110,
                            arcLabelMinAngle: 25,
                            innerRadius: 40,
                            valueFormatter: (value) => `${numberWithPoints(value.value)}%`,
                        },
                    ]}
                    height={230}
                />

                <Popup
                    lgShow={buyersPopup}
                    setLgShow={setBuyersPopup}
                    buttonText={'Ver lista completa'}
                    buttonClass='link-opacity-100 link-primary'
                    title={'Compradores'}
                >
                    <ParticipantSummaryData data={responseData.compradores} key={key}/>
                </Popup>
            </MainCard>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={6}>
            <MainCard sx={{
                mb: 2,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
            }} content={false}>
                <h1>Vendedores</h1>
                <PieChart
                    colors={colors}
                    series={[
                        {
                            arcLabel: (item) => `${item.value}%`,
                            data: sellers.map((item) => ({
                                id: item.label,
                                value: Number(item.valuePercentage),
                                label: key === 'valor' ?
                                    `${item.label} - R$ ${numberWithPoints(item.value)}` :
                                    `${item.label} - ${item.value} lote(s)`,
                            })),
                            highlightScope: {faded: "global", highlighted: "item"},
                            faded: {innerRadius: 30, additionalRadius: -30, color: "gray"},
                            cx: 120,
                            cy: 110,
                            arcLabelMinAngle: 25,
                            innerRadius: 40,
                            valueFormatter: (value) => `${numberWithPoints(value.value)}%`,
                        },
                    ]}
                    height={230}
                />

                <Popup
                    lgShow={sellersPopup}
                    setLgShow={setSellersPopup}
                    buttonText={'Ver lista completa'}
                    buttonClass='link-opacity-100 link-primary'
                    title={'Vendedores'}
                >
                    <ParticipantSummaryData data={responseData.vendedores} key={key}/>
                </Popup>
            </MainCard>
        </Grid>
        </Grid>

    </>) : (
        <BasicLoader
            message={"Carregando..."}
            height={10}
            notLeftShow={true}
        />
    );
}
