import {CircularProgress, Grid, Typography,} from "@mui/material";
import AnalyticEcommerce from "../../../component/AnalyticEcommerce";
import {useEffect, useState} from "react";
import axios from "axios";
import {ApiUrl} from "../../../auth/authMethods";
import {useUser} from "../../../auth/AuthProvider";
import {numberWithPoints} from "../../../utils/numberWithPoints";

export default function TotalGrid() {

    const [isLoading, setIsLoading] = useState(true);
    const [responseData, setResponseData] = useState("");

    const {user} = useUser()

    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${user?.token}`;
        axios
            .get(`${ApiUrl}/dashboard/totals`)
            .then(function (response) {
                setResponseData(response.data);
            }).finally(setIsLoading(false));
    }, []);


    return <>

        <Grid item xs={12} sm={6} md={4} lg={6} >
            <div className={'d-block'}>
                <Typography variant="h5" align="center">
                    Lotes
                </Typography>
                <AnalyticEcommerce
                    title="Lotes Ativos"
                    count={isLoading ?
                        <CircularProgress/> :
                        (responseData.lotesAtivos)
                    }
                    iconTotal={"1"}
                    title2="Lotes Inativos"
                    count2={isLoading ?
                        <CircularProgress/> :
                        (responseData.lotesInativos)
                    }
                    percentage2={0}
                    title3="Lotes Finalizados"
                    count3={isLoading ?
                        <CircularProgress/> :
                        (responseData.lotesFinalizados)
                    }
                    percentage3={0}
                    isLoss3
                    color3="warning"
                />

                <div className={'d-block'}>
                    <Typography variant="h5" align="center">
                        Valores
                    </Typography>
                    <AnalyticEcommerce
                        title="Total Recebido"
                        count={isLoading ?
                            <CircularProgress/> :
                            <>R$&nbsp;{numberWithPoints(responseData.valorRecebido)}</>
                        }
                        iconTotal={"1"}
                        title2="Total em Aberto"
                        count2={isLoading ?
                            <CircularProgress/> :
                            <>R$&nbsp;{numberWithPoints(responseData.valorEmAberto)}</>
                        }
                        percentage2={0}
                        title3="Total Repassado"
                        count3={isLoading ?
                            <CircularProgress/> :
                            <>R$&nbsp;{numberWithPoints(responseData.valorRepassado)}</>
                        }
                        percentage3={0}
                        isLoss3
                        color3="warning"
                        fontSize='18px'
                    />
                </div>
            </div>
        </Grid>
    </>
}