import PopupEdit from "../../../component/PopupEdit/PopupEdit";
import React, {useState} from "react";
import {ApiUrl} from "../../../auth/authMethods";
import TextField from "@mui/material/TextField";
import PersonIcon from '@mui/icons-material/Person';
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

export default function PopupEditRecipient({item, setLoadData}) {

    const baseUrl = `${ApiUrl}/pedidos/beneficiario`

    const [beneficiario, setBeneficiario] = useState(item.beneficiario)

    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const inputs = <>
        <TextField
            label="Beneficiário"
            inputProps={{
                maxLength: 150
            }}
            errortext="Campo obrigatório!"
            helperText={'Mantenha vazio para usar os dados da Rural Crédito!'}
            value={beneficiario}
            onChange={(e) => setBeneficiario(e.target.value)}
            fullWidth
            autoFocus
        />
    </>


    const icon = <PersonIcon fontSize="large" id="icon-svg"/>
    const buttonText = <span>
        <DriveFileRenameOutlineIcon fontSize="medium"/> &nbsp;
        Editar Beneficiário
    </span>

    const itemToState = () => {
        // console.log(item.beneficiario)
    }

    const returnData = () => {
        return {
            beneficiario: beneficiario?.length > 0? beneficiario : null
        }
    }

    return <PopupEdit
        baseUrl={baseUrl + `/${item.id}`} setLoadData={setLoadData}
        inputs={inputs} title={'Editar Beneficiário'} icon={icon}
        returnData={returnData} itemToState={itemToState}
        setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
        buttonText={buttonText} buttonClass='dropdown-item'
    />
}