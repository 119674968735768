import CurrencyInput from "../../../../component/CurrencyInput";
import TextField from "@mui/material/TextField";
import {handleChangeValue} from "../../../../utils/handleChangeValue";
import {InputLabelProps} from "../../../../utils/InputLabelProps";
import {FormControl, FormLabel, Radio, RadioGroup, Switch} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import SelectInput from "../../../../component/SelectInput";
import {ApiUrl} from "../../../../auth/authMethods";
import InputsParticipante from "../../../Users/components/inputs/InputsParticipante";
import displayAlertMsg from "../../../../component/AlertMsg/displayAlertMsg";
import React, {useEffect, useState} from "react";
import FileInput from "../../../../component/FileInput/FileInput";

export default function InputsAddPayment({
                                             setData, files, setFiles,
                                             error, setBlockSubmit,
                                             clear, setClear,
                                         }) {

    const [valor, setValor] = useState(0)
    const [tipo, setTipo] = useState("ANTECIPACAO")
    const [participante, setParticipante] = useState("")
    const [participanteId, setParticipanteId] = useState("")
    const [sendImmediately, setSendImmediately] = useState(false)
    const [pedidoId, setPedidoId] = useState('')

    const [pedidosList, setPedidosList] = useState([]);
    const [participantesList, setParticipantesList] = useState([])
    const [participanteCadastrado, setParticipanteCadastrado] = useState(true)

    useEffect(() => {
        const data = {
            valor,
            tipo,
            sendImmediately
        }
        if (participanteCadastrado)
            data.participanteId = participanteId
        else
            data.participante = participante

        setData(data)

    }, [valor, tipo, participante, participanteId, sendImmediately])

    useEffect(() => {
        if (clear) {
            setValor(0)
            setTipo("ANTECIPACAO")
            setParticipante("")
            setParticipanteId("")
            setSendImmediately(false)
            setPedidoId("")
            setFiles([])
        }
    }, [clear])


    return <>
        <br/>

        <SelectInput
            url={ApiUrl + '/pedidos/list-for-select'}
            list={pedidosList}
            set={setPedidosList}
            defaultValue={pedidoId}
            setObj={setPedidoId}
            returnList={() => {
                let returnList = []
                pedidosList.forEach((item) => {
                    returnList.push({
                        value: item.id,
                        label: `${item.evento} - lote ${item.numero}`
                    })
                })
                return returnList
            }}
            placeholder={"Lote *"}
            required={true}
            fullWidth
        />
        <CurrencyInput
            label="Valor Original"
            customInput={TextField}
            prefix='R$'
            value={valor}
            inputProps={{
                min: 1,
                step: ".01"
            }}
            onValueChange={(e) => handleChangeValue(e, setValor)}
            InputLabelProps={InputLabelProps}
            required
            fullWidth
            className='mt-2'
        />

        <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Tipo</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={tipo}
                onChange={(e) => setTipo(e.target.value)}
            >
                <FormControlLabel value="ANTECIPACAO" control={<Radio/>} label="Antecipação"/>
            </RadioGroup>
        </FormControl>

        <br/>
        <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Enviar para o BTG ao criar?</FormLabel>
            <RadioGroup
                value={sendImmediately}
                onChange={(e) => setSendImmediately(e.target.value === 'true')}
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            >
                <FormControlLabel value={false} control={<Radio/>} label="Não, aguardar próximo processamento"/>
                <FormControlLabel value={true} control={<Radio/>} label="Sim, enviar agora"/>

                {sendImmediately &&
                    <p className='text-black-50'>*Em caso de erro ao enviar para o BTG, o pagamento ficará como
                        'Criado'!</p>
                }
            </RadioGroup>
        </FormControl>


        <FormLabel component="legend">Participante (Quem receberá o pagamento)</FormLabel>

        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Typography variant="body1">
                Participante Não Cadatrado
            </Typography>
            <Switch
                checked={participanteCadastrado}
                onChange={() => setParticipanteCadastrado(prevValue => !prevValue)}
                color="primary"
            />
            <Typography variant="body1">
                Participante Cadastrado
            </Typography>
        </div>

        {participanteCadastrado ?
            <SelectInput
                url={ApiUrl + '/participante/list?hasPix=true&size=200000'}
                list={participantesList}
                set={setParticipantesList}
                defaultValue={participanteId}
                setObj={setParticipanteId}
                returnList={() => {
                    let returnList = []
                    participantesList.forEach((item) => {
                        returnList.push({value: item.id, label: `${item.nome} - ${item.documento}`})
                    })
                    return returnList
                }}
                placeholder={"Participante *"}
                required={true}
                fullWidth
            /> : <>
                <InputsParticipante
                    participante={participante}
                    setParticipante={setParticipante}
                    hideBankDetailsAndPix={false}
                    setBlockSubmit={setBlockSubmit}
                />
                <br/>
            </>
        }

        <FileInput
            selectedFile={files}
            setSelectedFile={setFiles}
            multiple
        />

        {error && displayAlertMsg(error)}
    </>
}